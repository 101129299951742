// color variants
@import 'themes-vars.module.scss';

// third-par
@import ' ~react-perfect-scrollbar/dist/css/styles.css';
@import ' ~slick-carousel/slick/slick.css';
@import ' ~slick-carousel/slick/slick-theme.css';

@import ' ~react-18-image-lightbox/style.css';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

* {
    box-sizing: border-box;
}

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
    overflow: hidden;
    .slider {
        .slide {
            opacity: 0.5;
            display: flex;
            justify-content: center;
            .custom-slider {
                gap: 40px;
            }
            &.selected {
                opacity: 1;
                .custom-slider {
                    display: flex;
                    flex-direction: column;
                }
            }
            &:not(.selected) {
                transform: scale(0.7);
                transform-origin: top;
            }
        }
    }
}

.project-info {
    .project-slider {
        .slick-list {
            padding: 0 !important;
        }
    }
    .slick-slide {
        opacity: 0.05;
    }
    .slick-active {
        opacity: 0.2;
    }
    .slick-current {
        opacity: 1 !important;
        .MuiTypography-root {
            color: $primaryMain;
        }
    }
}
.ril__inner {
    direction: ltr;
}
.ReactModal__Overlay {
    z-index: 99999 !important;
}

.p0 {
    padding: 0px;
}

.ml10 {
    margin-left: 10px;
}

.dx-datagrid-header-panel {
    margin-bottom: 10px !important;
}

.dark-text {
    color: #000 !important;
}

.dx-list-item-content {
    font-size: 14px !important;
}

.dx-datagrid .dx-row > td {
    font-size: 14px !important;
}

.card-sm-header .MuiCardHeader-root {
    padding: 15px !important;
}

.text-muted {
    color: #6c757d!important;
}

.study-card {
    position: relative;
    height: 228px;
    cursor: pointer;
    font-size: 0.9rem;
    .MuiCardHeader-root {
        padding: 12px;
        height: 50px;
    }
}

.design-card {
    .MuiCardHeader-root {
        padding: 12px;
        height: 50px;
    }
}

.study-schedule {
    .MuiCardHeader-root {
        height: 50px;
        padding: 12px;
    }
    .MuiLinearProgress-root {
        height: 10px;
    }
    .MuiPaper-root {
        height: 228px;
    }
}

.shadow-card:hover {
    opacity: .7;
    transition: .3s all ease;
    top: -5px;
    -webkit-box-shadow: 0 3px 50px -2px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 3px 50px -2px rgba(0, 0, 0, 0.2) !important;
}

.MuiContainer-root {
    max-width: none !important;
}

.toast-top {
    top: 80px !important;
}

.h740 {
    height: 740px;
}

.cdw-dialog {
    .MuiAppBar-root {
        background-color: #5e35b1 !important;
        border-radius: 25px;
    }
    .MuiDialog-paper {
        width: 90%;
        max-width: 1350px;
        border-radius: 25px;
        height: 95vh;
    }
    .MuiDialogTitle-root {
        border-bottom: 1px solid #dddddd78;
    }
}

.dx-header-row {
    .dx-datagrid-text-content {
        color: #5d5a5a;
        font-weight: bold;
    }
}

.dx-group-cell {
    color: #4a4747;
    font-weight: bold;
}

.sso-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer {
    cursor: pointer;
}

.main-m-0 main {
    margin-top: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    height: 100vh !important;
}

.title-card {
    font-weight: bold;
    .MuiCardContent-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }
    .title {
        margin-left: 5px;
    }
}

.dx-datagrid-headers {
    border-radius: 10px 10px 0px 0px;
}

.MuiLinearProgress-bar {
    background-color: #66aee7;
}

.MuiDialogContent-root {
    padding-top: 0px;
    margin-top: 15px;
}
